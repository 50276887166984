<template>
  <div class="app-container">
    <el-row class="page-buttons">
      <editor-button action="cancel" style="float:right" @click="close" />
    </el-row>
    <pagination
      v-if="numberOfItems > 0"
      :total="numberOfItems"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <el-table v-loading="loading" :data="items" border fit highlight-current-row style="width: 100%">
      <el-table-column :label="$t('eventlog.occurredAt')" width="260">
        <template slot-scope="scope">
          <el-date-picker v-model="scope.row.occurredAt" type="datetime" :format="dateFormat" readonly />
        </template>
      </el-table-column>

      <el-table-column :label="$t('eventlog.thread')" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.thread }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('eventlog.level')" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.level | levelFilter">
            {{ scope.row.level }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column :label="$t('eventlog.logger')" width="300">
        <template slot-scope="scope">
          <span>{{ scope.row.logger }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('eventlog.message')" width="700">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.message" placement="bottom" effect="dark">
            <span
              ><pre>{{ scope.row.message }}</pre></span
            >
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column align="center" :label="$t('table.actions')">
        <template slot-scope="scope">
          <el-button type="danger" icon="el-icon-delete" circle @click="deleteConfirm(scope.row.id)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import EditorButton from '@/components/crud/EditorButton';
import { closeCurrentTag } from '@/utils/tags';

export default {
  name: 'EventLogList',
  components: { Pagination, EditorButton },
  filters: {
    levelFilter(level) {
      const levelMap = {
        DEBUG: 'info',
        ERROR: 'danger',
        FATAL: 'danger',
        INFO: 'success',
        WARN: 'warning'
      };
      return levelMap[level];
    }
  },
  data() {
    return {
      listQuery: {
        limit: 20,
        page: 1
      }
    };
  },
  computed: {
    dateFormat() {
      return this.$store.getters.dateTimeFormat;
    },
    items() {
      return this.$store.getters['eventlog/loadedItems'];
    },
    loading() {
      return this.$store.getters['eventlog/isLoading'];
    },
    numberOfItems() {
      return this.$store.getters['eventlog/totalItemsCount'];
    }
  },
  created() {
    this.getList();
  },
  methods: {
    deleteConfirm(id) {
      this.$store
        .dispatch('notify/deleteConfirm')
        .then(() => {
          this.$store.dispatch('eventlog/deleteItem', { id: id }).then(() => {
            this.$store.dispatch('notify/deleteCompleted');
          });
        })
        .catch(() => {
          this.$store.dispatch('notify/deleteCancelled');
        });
    },
    getList(pageInfo) {
      this.$store
        .dispatch('eventlog/replaceItems', {
          query: { limit: this.listQuery.limit, page: this.listQuery.page }
        })
        .then(() => {
          if (pageInfo) {
            this.listQuery.page = pageInfo.page;
          }
        });
    },
    close() {
      closeCurrentTag(this);
    }
  }
};
</script>

<style scoped>
.page-buttons {
  margin-bottom: 1em;
}
.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
